import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../hoc/Layout"
import SectionIntro from "../components/Sections/SectionIntro/SectionIntro"

import InteractiveMap from "../components/InteractiveMapBob"

const schoolBookFairs = ({ data }) => {
  return (
    <>
      <Layout headerPages pages>
        <SectionIntro page="schoolBookFairs" />

        <div className="schoolBookFair">
          <section className="container info-box">
            <div className="row">
              <div className="col-6 col-md-12">
                <div className="image-container">
                  <Img fluid={data.bookFair.childImageSharp.fluid} alt="directSchoolImage" />
                </div>
                <div className="divider divider-rotate show hide">
                  <div className="divider-inner-height"></div>
                </div>
              </div>
              <div className="col-6 col-md-12">
                <h2>A book fair at your school</h2>
                <p>
                  At a Moonwood school book fair, children have the opportunity of browsing English books for all ages and interests, by a wide range of publishers, and being inspired by the colourful variety. Schools may request books in their local language too, making possible
                  a 'bilingual' or 'multilingual' school book fair, to suit the needs of students and teachers.
                </p>
                <p>
                  Preschool and primary school classes are very welcome to visit the book fair during the school day for a creative workshop programme including interactive storytelling on a theme. This is followed by an opportunity for the children to browse the books and write
                  their Wish Lists - a fun class activity enjoyed by everyone. The children may either purchase their books during school time or after school with their parents.
                </p>
                <p>
                  A school book fair is a celebration of reading that children, parents and teachers look forward to with great excitement and anticipation. Our aim at Moonwood is to provide events that inspire a love of reading, encouraging curiosity and an enjoyment of books
                  while creating fond memories for children.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-12">
                <h2>Free books for schools</h2>
                <p>
                  Moonwood school book fairs are very popular with children, parents and teachers and give schools a fabulous opportunity to receive generous amounts of free books as a result, enabling them to stock school libraries and classrooms with quality reading material in
                  English or a chosen foreign language.
                </p>
                <p>
                  Moonwood would like to invite your school to celebrate reading with a school book fair. Please feel free to{" "}
                  <a href="mailto:info@moonwood-books.com?subject=Interested in receiving a Moonwood School Book Fair Proposal">request a Moonwood School Book Fair Proposal</a>.
                </p>
              </div>

              <div className="col-6 col-md-12">
                <div className="image-container">
                  <Img fluid={data.freeBooks.childImageSharp.fluid} alt="freeBooksImage" />
                </div>
                <div className="divider hide show">
                  <div className="divider-inner-height"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-12">
                <div className="image-container">
                  <Img fluid={data.directSchool.childImageSharp.fluid} alt="freeBooksImage" />
                </div>
                <div className="divider divider-rotate hide show">
                  <div className="divider-inner-height"></div>
                </div>
              </div>
              <div className="col-6 col-md-12">
                <h2>Direct school orders</h2>
                <p>
                  Moonwood supplies books directly to schools, helping to stock school libraries and classrooms with a wide range of English and foreign language children's titles. Whether selecting non-fiction for curriculum-related units of inquiry, or fiction for guided
                  reading, classroom reading corners or a new school library, Moonwood is happy to advise and make suitable recommendations. <a href="mailto:info@moonwood-books.com?subject=Interested in receiving catalogues">Catalogues can be sent on request</a> and{" "}
                  <a href="mailto:info@moonwood-books.com?subject=Interested in direct school orders">generous school discounts are available</a>.
                </p>
              </div>
            </div>
          </section>
          <section className="container schoolBookFair-comments">
            <div className="row">
              <div className="col-6 col-md-12">
                <div className="comment-box">
                  <div className="topBorder"></div>
                  <h4>Last week was so much fun and once again these are great results! Thank you so much for your professionalism and super energy!</h4>
                  <h2>Tracey Jaffart</h2>
                  <p>Head of Library</p>
                  <p>Phorms Campus München</p>
                  <p>Munich, Germany</p>
                </div>
                <div className="comment-box">
                  <div className="topBorder" style={{ backgroundColor: "#D50029" }}></div>
                  <h4>Thank you very much for your visit, as always it's been a pleasure having you here. Such a great result would not be possible without your vibrant personality, artistic skills and boundless energy!</h4>
                  <h2>Marzena Polakowska</h2>
                  <p>Head Librarian</p>
                  <p>Szczecin International School</p>
                  <p>Szczecin, Poland</p>
                </div>
              </div>
              <div className="col-6 col-md-12">
                <div className="comment-box">
                  <div className="topBorder" style={{ backgroundColor: "#5064B0" }}></div>
                  <h4>Thank you so so much for an amazing two days at the Junior School. Everyone was delighted with your visit and the success of the book sale. Your enthusiasm combined with your calmness and organisational skills ensured that the two days ran smoothly.</h4>
                  <h2>Janee Lowndes</h2>
                  <p>Head of Library</p>
                  <p>British School of Paris</p>
                  <p>Paris, France</p>
                </div>
                <div className="comment-box">
                  <div className="topBorder"></div>
                  <h4>Of course the books are wonderful and quite enough to attract a lot of attention but the value you add is turning the event into a fabulous one...</h4>
                  <h2>Katarina Rantova</h2>
                  <p>School Library Leader</p>
                  <p>The English International School of Prague</p>
                  <p>Prague, Czech Republic</p>
                </div>
              </div>
            </div>
          </section>
          <div className="image-right-container">
            <InteractiveMap />

            <section className="container schoolBookFair-map">
              <div className="row">
                <div className="col-6 col-md-12">
                  <h2>
                    Would you like to hold
                    <br />a book fair at your school?
                  </h2>
                  <p>
                    We have many years of experience providing school
                    <br />
                    book fairs for international, bilingual and state
                    <br />
                    schools in numerous European countries.
                  </p>
                  <div>
                    <p>
                      <strong>
                        Please feel free to request
                        <br />
                        more information
                      </strong>
                    </p>
                    <a href="mailto:info@moonwood-books.com?subject=Interested in holding a school book fair" className="btn btn-page">
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <div className="image-right-container-bg"></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default schoolBookFairs

export const query = graphql`
  query {
    bookFair: file(relativePath: { eq: "school-book-fairs/book-fair.png" }) {
      childImageSharp {
        fluid(maxWidth: 660, maxHeight: 590, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    freeBooks: file(relativePath: { eq: "school-book-fairs/free-books.png" }) {
      childImageSharp {
        fluid(maxWidth: 639, maxHeight: 490, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    directSchool: file(relativePath: { eq: "school-book-fairs/direct-school.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 489, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`
